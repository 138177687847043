// import jQuery from "jquery";
// window.$ = window.jQuery = jQuery;
// import 'moment';
import 'bootstrap';
// import 'bootstrap-datetimepicker-npm/build/js/bootstrap-datetimepicker.min.js';
// import 'bootswatch';

import 'bootswatch/yeti/bootstrap.min.css';
import 'select2-bootstrap-css/select2-bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import 'bootstrap-datetimepicker-npm/build/css/bootstrap-datetimepicker.css';

//         'components': {
//             'source_filenames': (
//                 'bootswatch-dist/js/bootstrap.min.js',
//                 'moment/moment.js',
//                 'bootstrap3-datetimepicker/build/js/bootstrap-datetimepicker.min.js',
//             ),
//             'output_filename': 'js/components.js',
//         },
//     },


//     'STYLESHEETS': {
//         'components': {
//             'source_filenames': (
//                 'bootswatch-dist/css/bootstrap.css',
//                 'font-awesome/css/font-awesome.css',
//                 'bootstrap3-datetimepicker/build/css/bootstrap-datetimepicker.css',
//                 'select2-bootstrap-css/select2-bootstrap.css',
//             ),
//             'output_filename': 'css/components.css',
//         },
//     },
// }